import React from 'react'
import styled from 'styled-components'

const Title = styled.h3`
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 0;
`

interface SubTitleProps {
  className?: string
  children: any
  isLight?: boolean
}

const SubTitle: React.FC<SubTitleProps> = ({
  className = '',
  children,
  isLight = false,
}) => (
  <Title
    className={`${className} ${isLight ? 'text-white' : 'text-secondary'} `}
  >
    {children}
  </Title>
)

export default SubTitle
