import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Components
import Image from 'components/shared/ImageGatsby'

// SVG
import CustomLink from 'components/shared/CustomLink'
import ButtonOutline from 'components/elements/ButtonOutline'

const StyledImage = styled(Image)`
  @media (min-width: 992px) {
    height: 600px;
  }
  @media (max-width: 767px) {
    min-height: 200px;
  }
`

const Wrapper = styled.section`
  @media (min-width: 992px) {
    margin-bottom: 10rem;
  }
`

const ColumnDiv = styled.div`
  position: absolute;
  z-index: 2;
  left: -310px;
  top: 160px;
  min-width: 500px;
  min-height: 300px;
  max-width: 500px;
  background-color: ${(props) => props.theme.color.face.main};
  border-top-left-radius: 50px;

  @media (max-width: 991px) {
    border-radius: 0;
  }

  @media (min-width: 2000px) {
    left: -230px;
  }

  @media (max-width: 991px) {
    position: relative;
    top: 0;
    left: 0;
    min-width: 213px;
    max-width: 100%;
  }

  @media (max-width: 575px) {
    position: relative;
    top: 0;
    left: 0;
    max-width: 100%;
  }

  & h1 {
    font-family: ${(props) => props.theme.font.family.secondary};
    font-size: ${(props) => props.theme.font.size['30']};
    color: ${(props) => props.theme.color.text.dark};
    line-height: 30px;
    font-weight: 700;
    text-transform: uppercase;
    color: #858585;
  }
`

const Content = styled(ParseContent)`
  & img {
    width: 60px;
    height: 60px;
    opacity: 0.2;
    @media (max-width: 991px) {
      max-width: 40px;
      max-height: 40px;
    }
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-family: ${(props) => props.theme.font.family.secondary};
    font-size: ${(props) => props.theme.font.size['21']};
    color: ${(props) => props.theme.color.text.dark};
    line-height: 30px;
  }
`

const Row = styled.div`
  @media (min-width: 2000px) {
    justify-content: center !important;
  }
`

const Column = styled.div`
  max-width: 1120px;

  @media (min-width: 991px) {
    min-height: 600px;
  }
`

interface BannerRightProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_TextWithImage
}

const BannerRight: React.FC<BannerRightProps> = ({ fields }) => (
  <Wrapper className="mt-4 pb-4 pb-lg-0">
    <div className="container-fluid p-0">
      <Row className="row justify-content-end">
        <Column className="col-lg-8 col-12 position-relative">
          <div className="d-flex justify-content-center">
            <StyledImage
              image={fields.image}
              className="position-relative position-lg-absolute"
              alt="Lofthus"
              loading="lazy"
            />
          </div>

          <ColumnDiv className="p-lg-5 p-4">
            <h1>{fields?.title}</h1>
            {fields?.description && <Content content={fields.description} />}
            {fields?.link && (
              <ButtonOutline
                to={fields?.link?.url || ''}
                className="text-center my-3"
              >
                {fields?.link?.title}
              </ButtonOutline>
            )}
          </ColumnDiv>
        </Column>
      </Row>
    </div>
  </Wrapper>
)

export default BannerRight
