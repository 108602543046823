import React from 'react'
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/ImageGatsby'

// Third Party
import styled from 'styled-components'

const StyledBannerDefault = styled.section`
  min-height: 600px;
  max-height: 600px;
  @media screen and (min-width: 600px) and (max-width: 991px) {
    margin-bottom: 12rem;
  }

  @media (max-width: 991px) {
    min-height: 300px;
  }
`

const StyledImage = styled(Image)`
  min-height: 600px;
  max-height: 600px;
  @media (max-width: 991px) {
    min-height: 300px;
  }

  &.custom {
    @media (min-width: 992px){
      & img {
        object-position: 50% 90%;
      }
    }
  }

`

const Content = styled(ParseContent)`
  font-size: ${(props) => props.theme.font.size['24']};
`

interface BannerDefaultProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const BannerDefault: React.FC<BannerDefaultProps> = ({ fields }) => (
  <StyledBannerDefault className={`${fields?.hideOnMobile === true ? 'd-none d-lg-flex' : 'd-flex'} mt-4 position-relative align-items-center justify-content-center`}>
    <StyledImage
      alt="Lofthus Banner"
      image={fields.image}
      className={`position-absolute ${fields.positionImage && 'custom'}`}
      loading="lazy"
    />
    {fields.description && (
      <div className="container position-relative p-5">
        <Content content={fields.description} />
      </div>
    )}
  </StyledBannerDefault>
)

export default BannerDefault
