/* eslint-disable react/no-unused-prop-types */
import React, { useState } from 'react'
import ParseContent from 'components/shared/ParseContent'
import { motion, AnimatePresence } from 'framer-motion'
import Image from 'components/shared/ImageGatsby'

// Third Party
import styled from 'styled-components'

// SVG
import ArrowLeft from 'img/arrow-left.inline.svg'
import ArrowRight from 'img/arrow-right.inline.svg'

interface BannerSliderProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  location?: any
}

const StyledBannerSlider = styled.section`
  height: 600px;
  margin-bottom: 14rem;

  @media (max-width: 767px) {
    height: 450px;
    margin-bottom: 16rem;
  }
`

const SlideMotionImageWrapper = styled.div`
  height: 600px;
  @media (max-width: 767px) {
    height: 450px;
  }
`

const StyledImage = styled(Image)`
  height: 100%;
  width: 100%;
  max-width: 2000px;
  @media (max-width: 575px) {
    height: 60%;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: 25px;
    color: ${(props) => props.theme.color.text.dark};
    font-weight: 700;
    margin-bottom: 1rem !important;
  }
`

const SliderWrapper = styled.div`
  background: ${(props) => props.theme.color.face.light};
  width: 95%;
  height: auto;
  bottom: 0;
  border-top-right-radius: 50px;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25));

  @media (min-width: 768px) {
    width: 600px;
    min-height: 150px;
    bottom: -120px;
    right: 20%;
  }

  @media (max-width: 767px) {
    bottom: -190px;
  }

  & p {
    letter-spacing: 1px;
  }
`

const SlideMotionWrapper = styled.div`
  min-height: 270px;
  position: relative;

  @media (max-width: 575px) {
    min-height: 320px;
  }
`

const Slide = styled(motion.div)`
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: auto !important;
`

const Rectangle = styled.div`
  position: absolute;
  width: 157px;
  height: 15px;
  background: ${(props) => props.theme.color.face.contrast};
  transform: rotate(90deg);
  left: -4.5rem;
  top: 4.4rem;
`

const BannerSlider: React.FC<BannerSliderProps> = ({ fields }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  return (
    <StyledBannerSlider className="position-relative py-3 py-lg-5 mt-lg-5 d-flex justify-content-center">
      <SlideMotionImageWrapper className="w-100">
        <AnimatePresence>
          {fields?.slider?.slide?.map((slide, index: number) => {
            if (index !== currentIndex) {
              return null
            }

            return (
              <Slide
                className="d-flex justify-content-center"
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <StyledImage
                  image={slide?.image}
                  alt="Lofthus"
                  loading="lazy"
                />
              </Slide>
            )
          })}
        </AnimatePresence>
      </SlideMotionImageWrapper>

      <SliderWrapper className="position-absolute p-md-5 p-4">
        <Rectangle />
        <SlideMotionWrapper>
          <AnimatePresence>
            {fields?.slider?.slide?.map((slide, index: number) => {
              if (index !== currentIndex) {
                return null
              }

              return (
                <Slide
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  initial={{ opacity: 0, x: 400, overflow: 'hidden' }}
                  animate={{ opacity: 1, x: 0, overflow: 'hidden' }}
                  exit={{ opacity: 0, x: -400, overflow: 'auto' }}
                >
                  <Content content={fields?.slider?.title} />
                  <ParseContent content={slide?.description} />
                </Slide>
              )
            })}
          </AnimatePresence>
        </SlideMotionWrapper>
        <div className="d-flex justify-content-end">
          <button
            type="button"
            onClick={() => {
              setCurrentIndex((prevIndex) => prevIndex - 1)
            }}
            disabled={currentIndex === 0}
            style={currentIndex === 0 ? { opacity: '0.4' } : { opacity: '1' }}
          >
            <ArrowLeft />
          </button>
          <button
            type="button"
            onClick={() => {
              setCurrentIndex((prevIndex) => prevIndex + 1)
            }}
            disabled={currentIndex + 1 === fields?.slider?.slide?.length}
            style={
              currentIndex + 1 === fields?.slider?.slide?.length
                ? { opacity: '0.4' }
                : { opacity: '1' }
            }
          >
            <ArrowRight />
          </button>
        </div>
      </SliderWrapper>
    </StyledBannerSlider>
  )
}

export default BannerSlider
