import React from 'react'

// Third Party
import styled from 'styled-components'

// Components
import ButtonWhite from 'components/elements/ButtonWhite'
import Image from 'components/shared/ImageGatsby'
import TitleDefault from 'components/elements/TitleDefault'
import SubTitle from 'components/elements/SubTitle'

interface BannerSmallProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const StyledImage = styled(Image)`
  width: 100%;
  height: 420px;

  @media (max-width: 991px) {
    height: 300px;
  }

  & img {
    border-top-left-radius: 75px;

    @media (max-width: 991px) {
      border-top-left-radius: 50px;
    }
  }
`

const ImageDiv = styled.div`
  height: 420px;

  @media (max-width: 991px) {
    height: 300px;
  }
`

const ContentDiv = styled.div`
  z-index: 1;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`

const Rectangle = styled.div`
  width: 157px;
  height: 15px;
  background-color: ${(props) => props.theme.color.face.contrast};
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
`

const BannerSmall: React.FC<BannerSmallProps> = ({ fields }) => (
  <section
    className={`${
      fields?.hideOnMobile && 'd-none'
    } section position-relative align-items-start mb-5`}
  >
    <div className="container">
      <TitleDefault>{fields?.title}</TitleDefault>
      <SubTitle className="mb-4 mb-lg-5">{fields?.subtitle}</SubTitle>

      <ImageDiv className="position-relative overflow-hidden">
        <StyledImage
          alt="Lofthus"
          image={fields.image}
          className="position-absolute w-full"
          loading="lazy"
        />
        <ContentDiv>
          <ButtonWhite
            isAnchor
            to={fields?.link?.url || ''}
            className="text-center mb-5"
            target={fields?.link?.target || '_self'}
          >
            {fields?.link?.title}
          </ButtonWhite>
        </ContentDiv>
        <Rectangle />
      </ImageDiv>
    </div>
  </section>
)

export default BannerSmall
