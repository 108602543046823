import React from 'react'
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/ImageGatsby'
import ButtonDefault from 'components/elements/ButtonDefault'

// Third Party
import styled from 'styled-components'

const StyledBannerDefault = styled.section`
  height: calc(100vh - 150px);

  @media (max-width: 991px) {
    height: calc(100vh - 250px);
  }
`

const StyledImage = styled(Image)`
  &.custom {
    @media (min-width: 992px) {
      & img {
        object-position: 50% 90%;
      }
    }
  }
`

const StyledButtonDefault = styled(ButtonDefault)`
  max-width: 240px;
`

const Content = styled(ParseContent)`
  & h1 {
    font-family: ${(props) => props.theme.font.family.secondary};
    font-size: ${(props) => props.theme.font.size['60']};
    line-height: 70px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));

    @media (max-width: 991px) {
      font-size: ${(props) => props.theme.font.size['32']};
      line-height: 40px;
    }
  }

  & p {
    font-family: ${(props) => props.theme.font.family.secondary};
    font-size: ${(props) => props.theme.font.size['25']};
    color: #fff;
    font-weight: 600;
    //filter:  drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  }
`

interface BannerBigProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const BannerBig: React.FC<BannerBigProps> = ({ fields }) => (
  <StyledBannerDefault
    className={`${
      fields?.hideOnMobile === true ? 'd-none d-lg-flex' : 'd-flex'
    } position-relative align-items-center`}
  >
    <StyledImage
      alt="Lofthus Banner"
      image={fields.image}
      className={`position-absolute h-100 ${fields.positionImage && 'custom'}`}
      loading="lazy"
    />
    {fields.description && (
      <div className="container h-100 position-relative d-flex flex-column  justify-content-center">
        <Content content={fields.description} className="mb-4 pb-lg-3" />
        {fields.link && (
          <StyledButtonDefault to={fields.link.url || '/'}>
            {fields.link.title}
          </StyledButtonDefault>
        )}
      </div>
    )}
  </StyledBannerDefault>
)

export default BannerBig
